import { View, Text, useWindowDimensions, Pressable, Image, ImageBackground } from 'react-native';
import { useStyles } from '../Global.style';

export default function ProjectItem(props) {

    const tagsList = props.tags
    const { height, width } = useWindowDimensions();
    const { globalStyle } = useStyles(width, height);
    let fontsBreakpoints = []

    function responsive(width) {
        if (width > 1600) {
            fontsBreakpoints.h3 = 36
            fontsBreakpoints.p = 16
        } else if (width <= 1600 && width > 1366) {
            fontsBreakpoints.h3 = 36
            fontsBreakpoints.p = 16
        } else if (width <= 1366 && width > 600) {
            fontsBreakpoints.h3 = 32
            fontsBreakpoints.p = 16
        } else if (width < 600 && width > 360) {
            fontsBreakpoints.h3 = 30
            fontsBreakpoints.p = 14
        } else if (width <= 360) {
            fontsBreakpoints.h3 = 26
            fontsBreakpoints.p = 14
        }
    }

    responsive(width)

    const tags = tagsList.map((tag, index) => (
        <Text
            key={index}
            style={{
                position: "relative",
                marginRight: 5,
                marginBottom: 5,
                color: props.textColor,
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: "rgba(255,255,255,0.2)"
            }}>
            {tag}
        </Text>
    ));

    return (
        <>
            {props.onPress &&
                <Pressable style={{ flexDirection: "column", paddingTop: 20, maxWidth: (props.isWebMobile) ? "100%" : "30%", marginRight: (props.isWebMobile) ? 0 : 50 }} onPress={() => {
                    if (props.onPress) {
                        props.onPress();
                    }
                }}>
                    <ImageBackground
                        resizeMode={"contain"}
                        source={props.image}
                        style={{
                            height:(props.isWebMobile) ? 150 : width * 0.15,
                            width: "100%",
                            marginBottom: 20,
                            borderRadius: 10,
                            backgroundColor:"#000",
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 0,
                            },
                            shadowOpacity: 0.45,
                            shadowRadius: 20,
                            elevation: 5,

                        }}
                    />
                    <Text style={[globalStyle.h3, {
                        color: props.titleColor,
                        fontSize: fontsBreakpoints.h3,
                        lineHeight: fontsBreakpoints.h3
                    }]}
                        accessibilityRole="header"
                        aria-level="3">
                        {props.title}
                    </Text>
                    <View style={{ flexDirection: "row", marginVertical: 10, flexWrap: 'wrap' }}>{tags}</View>
                    <Text style={[globalStyle.pN, {
                        color: props.textColor,
                        maxWidth: (props.isWebMobile) ? "100%" : "80%",
                        fontSize: fontsBreakpoints.p,
                        lineHeight: fontsBreakpoints.p + 12
                    }]}>
                        {props.description}
                    </Text>
                </Pressable>
            }

            {!props.onPress &&
                <View style={{ flexDirection: "column", paddingTop: 20, maxWidth: (props.isWebMobile) ? "100%" : "30%", marginRight: (props.isWebMobile) ? 0 : 50 }} onPress={() => {
                    if (props.onPress) {
                        props.onPress();
                    }
                }}>
                    <ImageBackground
                        resizeMode={"cover"}
                        source={props.image}
                        style={{
                            height:(props.isWebMobile) ? 150 : width * 0.15,
                            width: "100%",
                            marginBottom: 20,
                            borderRadius: 10,
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 0,
                            },
                            shadowOpacity: 0.45,
                            shadowRadius: 20,
                            elevation: 5,

                        }}
                    />
                    <Text style={[globalStyle.h3, {
                        color: props.titleColor,
                        fontSize: fontsBreakpoints.h3,
                        lineHeight: fontsBreakpoints.h3
                    }]}
                        accessibilityRole="header"
                        aria-level="3">
                        {props.title}
                    </Text>
                    <View style={{ flexDirection: "row", marginVertical: 10, flexWrap: 'wrap', width: "95%" }}>{tags}</View>
                    <Text style={[globalStyle.pN, {
                        color: props.textColor,
                        maxWidth: (props.isWebMobile) ? "100%" : "80%",
                        fontSize: fontsBreakpoints.p,
                        lineHeight: fontsBreakpoints.p + 12
                    }]}>
                        {props.description}
                    </Text>
                </View>
            }
        </>

    )

}






import React, { useEffect, Children, useRef, forwardRef, useState } from 'react';
import { View, StyleSheet, Dimensions, Easing, Text, useWindowDimensions } from 'react-native';


import { isWeb } from '../PlatformSpecific';

const { width, height } = Dimensions.get('window');
const BREAKPOINT = 1366
const canvasWidth = 720
let canvasHeight = height
let squareSize = width > BREAKPOINT ? 122 : 90
let flowerSize = width > BREAKPOINT ? 150 : 120 
let stages = []
let firstPageAlpha = 1

function drawSquare(ctx, x, y, w, h) {
  ctx.beginPath()
  ctx.rect(x, y, w, h, 5)
  ctx.stroke();
  ctx.closePath()
}

function deg2rad(ang) {
 return ang * (Math.PI/180);
}

function drawFlower(ctx, x, y, r, rot) {
  ctx.beginPath()
  ctx.arc(x, y, r * 0.3, 0 *Math.PI, 2*Math.PI)
  ctx.fill();
  ctx.closePath()
  rot *= 0.5
  for (let i=rot; i < rot + 360; i+=15 ) {
    let nX = r * Math.cos(deg2rad(i))
    let nY = r * Math.sin(deg2rad(i))
    ctx.beginPath()
    ctx.moveTo(x, y);
    ctx.lineTo(x + nX, y + nY);//debug
    ctx.stroke();
    ctx.closePath()
  }
}

function drawShapes(ctx, scrollY, scrollYMax, activeIndex, items, height) {
  

  let perc = scrollY/scrollYMax
  let expIndex = Math.floor(scrollY / height)
 
  if (expIndex < 0) {
    expIndex = 0
  } else if (expIndex >= items.length) {
    expIndex = items.length - 1
  }
  const limitY = expIndex * height;
  let offsetY = scrollY - limitY;
  let subPerc = (offsetY/height)
  const stageIdx = expIndex

  //clean
  //const MAX_COLOR = 0xFF
  //Math.round(MAX_COLOR - (MAX_COLOR * perc)).toString(16)
  firstPageAlpha = (expIndex == 0) ? scrollY/height : 1
  let bgColor = items[activeIndex].shapeColor
  ctx.clearRect(0, 0, canvasWidth, canvasHeight )

  // var grd = ctx.createLinearGradient(180, 200, 800, 200);
  // grd.addColorStop(0, bgColor);
  // grd.addColorStop(1, "#CCCCCC");
  
  ctx.fillStyle = bgColor
  ctx.strokeStyle = bgColor
  ctx.lineWidth = 4;

  // start
  for (let i=0; i < stages[stageIdx].length; i++) {
    let square = stages[stageIdx][i]
    let diffX = (square.endX - square.startX) * subPerc
    
    drawSquare(ctx,  
      square.startX + diffX + (width > 1366 ? 150 : 300 ),  
      square.startY, 
      squareSize, 
      squareSize
    )
  }
  ctx.lineWidth = 4
  ctx.fillStyle = bgColor
  ctx.strokeStyle = bgColor
  drawFlower(ctx, canvasWidth * 0.6, canvasHeight * 0.75 , flowerSize, 90 * perc )

}

const AnimatedGeomShapes = forwardRef(({activeIndex, scrollY, scrollYMax, items}, ref) => {

  const {height, width} = useWindowDimensions();
  canvasHeight = height
  squareSize = width > BREAKPOINT ? 122 : 90
  flowerSize = width > BREAKPOINT ? 150 : 120 


  stages = [ 
    [     
    //stage 1
      {
        startX: squareSize,
        startY: squareSize,
        endX: squareSize,
        endY: squareSize
      },
      {
        startX: squareSize * 2,
        startY: squareSize * 2,
        endX: squareSize * 2,
        endY: squareSize * 2
      },
      {
        startX: squareSize * 3,
        startY: squareSize * 3,
        endX: squareSize * 3,
        endY: squareSize * 3
      }
    ],
    [
      
    //stage 2
      {
        startX: squareSize,
        startY: squareSize,
        endX: squareSize * 2,
        endY: squareSize
      },
      {
        startX: squareSize * 2,
        startY: squareSize * 2,
        endX: squareSize * 3,
        endY: squareSize * 2
      },
      {
        startX: squareSize * 3,
        startY: squareSize * 3,
        endX: squareSize * 3,
        endY: squareSize * 3
      },
    ],
    [//stage 3
      {
        startX: squareSize * 2,
        startY: squareSize,
        endX: squareSize * 3,
        endY: squareSize
      },
      {
        startX: squareSize * 3,
        startY: squareSize * 2,
        endX: squareSize * 3,
        endY: squareSize * 2
      },
      {
        startX: squareSize * 3,
        startY: squareSize * 3,
        endX: squareSize * 3,
        endY: squareSize * 3
      },
    ],
    [//stage 4
      {
        startX: squareSize * 3,
        startY: squareSize,
        endX: squareSize * 3,
        endY: squareSize
      },
      {
        startX: squareSize * 3,
        startY: squareSize * 2,
        endX: squareSize * 3,
        endY: squareSize * 2
      },
      {
        startX: squareSize * 3,
        startY: squareSize * 3,
        endX: squareSize * 3,
        endY: squareSize * 3
      },
    ]
  ]


  if (!isWeb) {
    return (<Text> Canvas not supported for Native </Text>)
  }

  let canvas = useRef(null)
  let [ctx, setContext] = useState()

  
  useEffect(() => {
    var ctx = canvas.current.getContext("2d");
    drawShapes(ctx, scrollY, scrollYMax, activeIndex, items, height)
    setContext(ctx)
  }, [])

  useEffect(() => {
    if (ctx) {
      drawShapes(ctx, scrollY, scrollYMax, activeIndex, items, height)
    }
  }, [scrollY, height, width, scrollYMax, activeIndex])


  return (
    <canvas ref={canvas} style={{
      flex: 1,
      position: "absolute",
      right: 0,
      opacity: firstPageAlpha
    }} width={canvasWidth} height={canvasHeight}  />
  );

});



export default AnimatedGeomShapes;
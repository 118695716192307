import React, { useEffect, Children, useRef, forwardRef, useState } from 'react';
import { View, StyleSheet, Dimensions, Easing, useWindowDimensions } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedScrollHandler,
  interpolate,
  Extrapolate,
  interpolateColor,
  withTiming,
  withDelay,
  runOnJS,
  

} from 'react-native-reanimated';

import { isWeb } from '../PlatformSpecific';

let childStates = [] // DONT MOVE TO LOCAL, THAT'S KEEP THE CHILD GLOBAL STATES

const AnimatedPage = forwardRef(({ children, scrollY, onScroll, activeIndex }, ref) => {

  const {height} = useWindowDimensions();
  const svHeight = height;

  const createAnimatedStyle = (index, opacityFactor = 1.4) => {
   
    if (isWeb) {
      //window._frameTimestamp = null //withTiming issue
    }
    
    return useAnimatedStyle(() => {
      
      let y = scrollY.value;
      const limitY = index * svHeight;
      let offsetY = y - limitY;
     
      if (!childStates[index]) {
        childStates[index] = {}
        childStates[index].speed = y
        childStates[index].transY = 0
      }

      childStates[index].initialY = index * svHeight 
      childStates[index].speed -= y
      
      let speed = childStates[index].speed
      const inputRange = [-svHeight, 0, svHeight];

      const bottom = childStates[index].transY + (index * svHeight) + svHeight
      const top = childStates[index].transY + (index * svHeight)
     
      if (speed > 0) {//MOVING DOWN
        if (top >= 0 ) {
          speed *= 0.15 // going out
        } else if (bottom <= svHeight * 0.10) {
          //RESET
          speed = 0
          childStates[index].transY = -y
        }
      } else if (speed < 0){//MOVING UP
        if (top <= 0 ) {
          speed *= 0.15 // going out
        } else if (top >= svHeight * 0.10) {
           //RESET
          speed = 0 
          childStates[index].transY = -y
        }
      } else if ((top < -(svHeight * 0.1) || top > svHeight) ) {
         //RESET
        speed = 0
        childStates[index].transY = -y
      }

      let opacity = interpolate(
        offsetY * opacityFactor,
        inputRange,
        [0, 1, 0],
        Extrapolate.CLAMP
      );
     
      childStates[index].transY += speed 
      let newTop = childStates[index].initialY + childStates[index].transY
      childStates[index].speed = y 

      return {
        height: svHeight,
        position: 'absolute',
        opacity,
        top: newTop,
        zIndex: index == activeIndex ? 999 : 0
      };
    });
  };
  
  return (
    <Animated.View style={[styles.scrollView, { height: svHeight, overflow: 'hidden', position: 'absolute' }]}>
      {Children.map(children, (child, index) => (
        <Animated.View style={createAnimatedStyle(index)} key={index}>
          {child}
        </Animated.View>
      ))}
    </Animated.View>
  );
});

const styles = StyleSheet.create({
  scrollView: {
    width: '100%',
  },
});

export default AnimatedPage;
import { View, Text, useWindowDimensions } from 'react-native';
import { useStyles } from '../Global.style';
import { TouchableOpacity } from 'react-native-gesture-handler';
import {
  getDeviceClass,
  DESKTOP_LARGE,
  DESKTOP_MEDIUM,
  DESKTOP_SMALL,
  TABLET,
  MOBILE,
} from "../utils/DisplayHelper";

const content = [
  {
    title: "Applied Machine Learning",
    headlines: [
      "ChatGPT Integration",
      "Object Detection and Computer Vision",
      "Generative AI and Transfer Models",
      "Prediction and Reporting",
    ]
  },
  {
    title: "Location Based Applications",
    headlines: [
      "GeoJSON Processing",
      "Drone Tracking",
      "Driver Based Applications",
      "Fleet Tracking"
    ]
  },
  {
    title: "Hardware",
    headlines: [
      "Augmented Reality",
      "Bluetooth Design and Development",
      "Point Of Sale Technology",
      "Embedded Systems"
    ]
  },
  {
    title: "Application Development",
    headlines: [
      "Super App Development",
      "Native iOS and Android Development",
      "React and React Native Client Development"
    ]
  }
]

export default function Home({textColor, goToPageIndex, isWebMobile, nativeID, onLayout}) {

    const { height, width } = useWindowDimensions();
    const { globalStyle, greenButton } = useStyles(width, height);
    let fontsBreakpoints = []
    let columnsBreakpoints = []
    const deviceClass = getDeviceClass({width});

    console.log(deviceClass)

    function responsive() {
        if (deviceClass == DESKTOP_LARGE) {
          fontsBreakpoints.h1 = 64
          fontsBreakpoints.p = 18
          fontsBreakpoints.columnTxt = 14
          columnsBreakpoints.width = "23%"
        }else if (deviceClass == DESKTOP_MEDIUM) {
          fontsBreakpoints.h1 = 46
          fontsBreakpoints.p = 16
          fontsBreakpoints.columnTxt = 14
          columnsBreakpoints.width = "29%"
        } else if (deviceClass == DESKTOP_SMALL) { 
          fontsBreakpoints.h1 = 42
          fontsBreakpoints.p = 14
          fontsBreakpoints.columnTxt = 12
          columnsBreakpoints.width = "25%"
        } else if (deviceClass == TABLET) {
          fontsBreakpoints.h1 = 38
          fontsBreakpoints.p = 14
          fontsBreakpoints.columnTxt = 12
          columnsBreakpoints.minWidth = "44%"
          columnsBreakpoints.width = "50%"
        } else if (deviceClass == MOBILE) {
          fontsBreakpoints.h1 = 34
          fontsBreakpoints.p = 14
          fontsBreakpoints.columnTxt = 16
          columnsBreakpoints.width = "100%"
        }
      }

    responsive()

    function SkillSection({style, title, headlines}) {
      return (
        <View style={[style, {marginRight:20,marginBottom: 25, width:columnsBreakpoints.minWidth, maxWidth:columnsBreakpoints.width}]}>
          <Text style={[globalStyle.p, { fontSize: fontsBreakpoints.columnTxt, color: textColor, fontFamily: 'Avenir-Black',marginBottom:2 }]} >{title}</Text>
          { headlines.map((headline, index) => (
              <Text key={index} style={[globalStyle.p, { fontSize: fontsBreakpoints.columnTxt, color: textColor}]} >{headline}</Text>
          ))}
        </View>
      )
    }

    return (
      <View nativeID={nativeID} style={{
        width: width * 0.83,
        minHeight: height / 1.8,
        alignItems: "center",
        //backgroundColor:"#ccc",
        flexDirection: (isWebMobile) ? "column" : "row"
      }}
      onLayout={(event) => {
        let {x, y, width, height} = event.nativeEvent.layout;
        if (onLayout) {
          console.log("has on layout", height);
          onLayout({height});
        }
      }}
      >
        <View style={{ width: (isWebMobile) ? "100%" : "100%"}}>
          <Text style={[globalStyle.h1, {
            color: textColor,
            fontSize: fontsBreakpoints.h1,
            lineHeight: fontsBreakpoints.h1 + 12,
            marginBottom: (isWebMobile) ? 0 : 100,
            width: (isWebMobile) ? "100%" : "70%"
          }]}
            accessibilityRole="header"
            aria-level="1">
            We're development specialists who execute highly technical projects.
          </Text>
          {isWebMobile &&
            <View>           
            <View style={[globalStyle.separatorLine, {marginRight: 20, zIndex: 0, top:45}]}/>
            <TouchableOpacity onPress={() => goToPageIndex(1)} style={[greenButton.container, {margin: 20, alignSelf: "flex-end"}]} >
              <Text style={greenButton.text}>Go to projects</Text>
            </TouchableOpacity>
            </View>
          }
          {!isWebMobile &&
            <View style={{flexDirection:'row'}}>
              <View style={[globalStyle.separatorLine, {marginRight: 20, zIndex: 99}]}/>
              <TouchableOpacity onPress={() => goToPageIndex(1)} style={greenButton.container} >
                <Text style={greenButton.text}>Go to projects</Text>
              </TouchableOpacity>
            </View>
          }
          <View style={{flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>
            { content.map( (c, index) => (              
              <SkillSection key={index} title={c.title} headlines={c.headlines}></SkillSection>
              )
            )}                  
          </View>
        </View>

         
        
        
      </View>

    )

}






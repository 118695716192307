import { View, Text, useWindowDimensions } from 'react-native';
import { useStyles } from '../Global.style';
import {
  getDeviceClass,
  DESKTOP_LARGE,
  DESKTOP_MEDIUM,
  DESKTOP_SMALL,
  TABLET,
  MOBILE,
} from "../utils/DisplayHelper";

const items = [
  {
    title: "Custom Software Development",
    services: [
      "Create your MVP",
      "Architect and develop a new feature",
      "Migrate and adapt your app to a new platform",
      "iOS and Android app development",
      "Applied Machine Learning"
    ],
  },
  {
    title: "Software Maintenance and Administration",
    services: [
      "Keep the apps running",
      "Make sure they are secure",
      "Keep them updated, current, and compatible",
      "Identify Issue and offer solutions",

    ],
  },
  {
    title: "Omni-Channel Ecommerce",
    services: [
      "Restaurant and Retail Technology Architecture ",
      "E-commerce Web and Mobile Applications Development",
      "Point of Sale and Kiosk Technology Consulting",
      "Kitchen Display Systems",
      "Customer Displays",
      "Analytics and Data Warehousing.",
    ],
  },

];

export default function Services(props) {

  const { height, width, scale, fontScale } = useWindowDimensions();
  const { globalStyle } = useStyles(width, height);
  const deviceClass = getDeviceClass({ width });
  let fontsBreakpoints = []


  function responsive() {
    if (deviceClass == DESKTOP_LARGE) {
      fontsBreakpoints.h2 = 34
      fontsBreakpoints.h3 = 22
      fontsBreakpoints.p = 16
    } else if (deviceClass == DESKTOP_MEDIUM) {
      fontsBreakpoints.h2 = 34
      fontsBreakpoints.h3 = 22
      fontsBreakpoints.p = 16
    } else if (deviceClass == DESKTOP_SMALL) {
      fontsBreakpoints.h2 = 30
      fontsBreakpoints.h3 = 22
      fontsBreakpoints.p = 14
    } else if (deviceClass == TABLET) {
      fontsBreakpoints.h2 = 30
      fontsBreakpoints.h3 = 22
      fontsBreakpoints.p = 14
    } else if (deviceClass == MOBILE) {
      fontsBreakpoints.h2 = 30
      fontsBreakpoints.h3 = 22
      fontsBreakpoints.p = 14
    }
  }

  responsive()

  const ServicesListItems = (props) => {
    const list = props.servicesList;
    return list.map((service, index) => (
      <Text
        key={index}
        style={{
          position: "relative",
          marginBottom: 5,
          color: props.textColor,
          maxWidth: (props.isWebMobile) ? "100%" : "90%",
          fontSize: fontsBreakpoints.p,

        }}
      >
        {service}
      </Text>
    ));
  };

  const ourServices = items.map((item, index) => {
    return (
      <View key={index} style={{
        width: width,
        flexDirection: "column",
        paddingTop: 20,
        maxWidth: (props.isWebMobile) ? "100%" : "33.33333%",
        borderBottomWidth: 1,
        borderRightWidth: index >= (items.length - 1) && !props.isWebMobile ? 0 : 1,
        borderColor: props.textColor,
        paddingLeft: index > 0 && !props.isWebMobile ? 20 : 0,
        paddingRight: 20,
        paddingBottom: 20
      }}>
        <Text style={[globalStyle.h3, {
          color: props.textColor,
          fontSize: fontsBreakpoints.h3,
          lineHeight: fontsBreakpoints.h3,
          marginBottom: 10,
          textTransform:"uppercase"
        }]}
          accessibilityRole="header"
          aria-level="3">
          {item.title}
        </Text>
        <View style={{ flexDirection: "column", marginVertical: 10 }}>
          <ServicesListItems servicesList={item.services} />
        </View>
      </View>
    )

  });

  return (

    <View nativeID={props.nativeID}>
      <Text style={[globalStyle.h2, {
        color: props.textColor,
        fontSize: fontsBreakpoints.h2,
        lineHeight: fontsBreakpoints.h2 + 12
      }]}
        accessibilityRole="header"
        aria-level="2">
        Our Services
      </Text>
      <View style={{ flexDirection: (props.isWebMobile) ? "column" : "row", width: props.isWebMobile ? width * 0.88 : "100%" }}>
        {ourServices}
      </View>
      <View style={{
        width: width,
        flexDirection: "column",
        paddingTop: 20,

      }}>
        <Text style={[globalStyle.h3, {
          color: props.textColor,
          fontSize: 16,
          lineHeight: 0,
          marginTop: 30,
        }]}
          accessibilityRole="header"
          aria-level="2">
          <Text style={{position:"relative",fontSize:54,top:15}}>*</Text> Flexible to accommodate your needs
        </Text>
        <View style={{ flexDirection: "column", marginVertical: 10 }}>
          <Text style={[globalStyle.pN, {
            color: props.textColor,
            fontSize: fontsBreakpoints.p,
            padding:0,
            margin:0,
            marginLeft:30,
            maxWidth: (props.isWebMobile) ? "60%" : "30%",
          }]}>
            In collaboration with senior designers and product managers, we can provide an end-to-end project development lifecycle.
          </Text>
        </View>
      </View>
    </View>

  )

}






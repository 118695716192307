import { ActivityIndicator, View, StyleSheet } from 'react-native';
import { loading } from '../Global.style';

export default function LoadingView(props) {

    return (

        <View style={[loading.container, loading.horizontal]}>
            <ActivityIndicator size="large" color={"blue"} />
        </View>

    )

}






import React, { useEffect, Children, useRef, forwardRef, useState, createContext, useCallback } from 'react';
import { View, SafeAreaView, Dimensions, Easing, Text, useWindowDimensions, ImageBackground, Image, TouchableOpacity, Modal, Pressable } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  Extrapolate,
  withTiming,
  withDelay,
  useAnimatedRef,
  useDerivedValue,
  interpolate,
  runOnJS
} from 'react-native-reanimated';
import { Ionicons, Foundation } from '@expo/vector-icons';
import { isWeb } from '../PlatformSpecific';
import { useStyles } from '../Global.style';
import {
  getDeviceClass,
  DESKTOP_LARGE,
  DESKTOP_MEDIUM,
  DESKTOP_SMALL,
  TABLET,
  MOBILE,
} from "../utils/DisplayHelper";

import ProjectItem from './ProjectItemPlayground';

if (isWeb) {
  window._frameTimestamp = null//withTiming issue
}

const images = {
  joyntly: require('../assets/joyntly_iphone_folder.png'),
  airguard: require('../assets/airguard.png'),
  sportsbox: require('../assets/sportsbox.jpeg'),

};

const playerConfig = {
  animOffset: {
    DESKTOP_LARGE: {
      left: -50,
      top: 50,
    },
    DESKTOP_MEDIUM: {
      left: -50,
      top: 50,
    },
    DESKTOP_SMALL: {
      left: -50,
      top: 50,
    },
    TABLET: {
      left: 0,
      top: 0,
    },
    MOBILE: {
      left: 0,
      top: 0,
    },
  },
};
const items = [
 
  {
    name: 'SportsBox',
    type: 'ipad',
    image: 'sportsbox',
    url: 'https://sportsboxapp.airro.dev',
    sizes: {
      DESKTOP_LARGE: {
        height: 600,
        width: 400,
      },
      DESKTOP_MEDIUM: {
        height: 600,
        width: 400,
      },
      DESKTOP_SMALL: {
        height: 600,
        width: 400,
      },
      TABLET: {
        height: 600,
        width: 400,
      },
      MOBILE: {
        height: 600,
        width: 400,
      },
    },
    tags: ["tag3", "tag4"],
    description: "An AI Driven sports app. Custom NER Model."
  },
  {
    name: 'Restaurant',
    type: 'iphone',
    image: 'restaurant',
    url: 'https://dishroulette.airro.dev',
    sizes: {
      DESKTOP_LARGE: {
        height: 600,
        width: 400,
      },
      DESKTOP_MEDIUM: {
        height: 600,
        width: 400,
      },
      DESKTOP_SMALL: {
        height: 600,
        width: 400,
      },
      TABLET: {
        height: 600,
        width: 400,
      },
      MOBILE: {
        height: 600,
        width: 400,
      },
    },
    tags: ["tag5", "tag6"],
    description: "An AI Driven sports app. Custom NER Model."
  },
  {
    name: 'Joyntly',
    type: 'iphone',
    image: 'joyntly',
    url: 'https://joyntlywww.airro.dev',
    sizes: {
      DESKTOP_LARGE: {
        height: 700,
        width: 400,
      },
      DESKTOP_MEDIUM: {
        height: 600,
        width: 800,
      },
      DESKTOP_SMALL: {
        height: 600,
        width: 400,
      },
      TABLET: {
        height: 600,
        width: 400,
      },
      MOBILE: {
        height: 600,
        width: 400,
      },
    },
    tags: ["React Native", "NodeJS", "MongoDB"],
    description: "A map based social networking app."
  },

];

const ProjectsPlayer = ({ style, showPlayer, projectIndex, didClose }) => {

  const { height, width } = useWindowDimensions();
  const { globalStyle, player } = useStyles(width, height);

  const aref = useAnimatedRef();
  const open = useSharedValue(false);
  const animatedVar = useDerivedValue(() => { return open.value ? withTiming(1) : withTiming(0) }, [open]);

  const baseDelay = 0;
  const staggerDelay = 0;
  const fadeInDuration = 1000;
  const xMoveDuration = 1000;

  const iframeNode = useRef(null)
  const selectedIndex = useRef();
  
  const [applicationName, setApplicationName] = useState("");
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");

  const deviceClass = getDeviceClass({ width });
  const playerOffsetLeft = playerConfig.animOffset[deviceClass].left;
  const playerOffsetTop = playerConfig.animOffset[deviceClass].top;
  const iconSize = deviceClass == "MOBILE" ? 35 : 55;

  selectedIndex.current = projectIndex;

  useEffect(() => {
    if (showPlayer && projectIndex >= 0) {
      pressOpen({ index: projectIndex });
    }
  }, [showPlayer, projectIndex])

  const playerStyle = useAnimatedStyle(() => ({
    left: interpolate(animatedVar.value, [0, 1], [playerOffsetLeft, 0], Extrapolate.CLAMP),
    top: interpolate(animatedVar.value, [0, 1], [playerOffsetTop, 0], Extrapolate.CLAMP),
    opacity: animatedVar.value,
    zIndex: open.value ? 999 : 0,
    width: width - ((1 - animatedVar.value) * 50),
    height: height - ((1 - animatedVar.value) * 50)

  }), [animatedVar, width, height]);




  const pressOpen = async ({ index }) => {
    console.log("called open", index, items[index])
    console.log("the device class is", deviceClass);
    console.log("sizes are", items[index].sizes[deviceClass].width, items[index].sizes[deviceClass].height)
    console.log("the selected index is", selectedIndex.current);
    let playerHeight = items[index].sizes[deviceClass].height;
    let playerWidth = items[index].sizes[deviceClass].width;
    if (deviceClass === 'MOBILE') {
      playerHeight = height - 70;
      playerWidth = width;
    }
  
    selectedIndex.current = index;
    if (index >= 0) {
      console.log("loading iframe with index: ", index);
      iframeNode.current.src = items[index].url
      iframeNode.current.src += '';  // force the reload
      iframeNode.current.width = playerWidth
      iframeNode.current.height = playerHeight
    } else {
      iframeNode.current.src = '';
      iframeNode.current.width = 0
      iframeNode.current.height = 0
    }
    animatedVar.current = 1
    open.value = true;
    selectedIndex.current = index;
    setApplicationName(items[index].name);
    setTags(items[index].tags);
    setDescription(items[index].description)
  }

  const pressClose = async () => {
    iframeNode.current.src = '';
    selectedIndex.current = -1;
    animatedVar.current = 0
    open.value = false;
    if (didClose) {
      didClose();
    }
  }

  const createFadeInAnimation = (index) => {
    const opacity = useSharedValue(0);
    const translateX = useSharedValue(-200 * (index + 1));

    useEffect(() => {
      const delay = baseDelay + staggerDelay * index;
      opacity.value = withDelay(delay, withTiming(1, { duration: fadeInDuration }));
      translateX.value = withDelay(delay, withTiming(0, { duration: xMoveDuration }));
    }, [opacity, translateX, fadeInDuration, baseDelay, staggerDelay, index]);

    const fadeInStyle = useAnimatedStyle(() => {
      return {
        opacity: opacity.value,
        transform: [{ translateX: translateX.value }],
      };
    });

    return fadeInStyle;
  };

  let playerHeight = selectedIndex.current >= 0 ? items[selectedIndex.current].sizes[deviceClass].height : 0;
  let playerWidth = selectedIndex.current >= 0 ? items[selectedIndex.current].sizes[deviceClass].width : 0;
  if (deviceClass === 'MOBILE') {
    playerHeight = height - 75;
    playerWidth = width;
  }

  return (
    <SafeAreaView style={[style, { position: 'absolute' }]}>
      <Animated.View
        style={[
          {
            backgroundColor: '#222222',
            position: "absolute",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            overflow: 'hidden',
            width: width,
            height: height,
          },
          playerStyle
        ]}
      >
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <View style={{ 
            padding: 12, 
            width: projectIndex >= 0 ? playerWidth + 25 : 0,
            height: projectIndex >= 0 ? playerHeight + 25 : 0,
            backgroundColor:"#000",
            justifyContent:"center",
            borderRadius: 5,       
            marginTop: deviceClass == "MOBILE" ? 50 : 0
          }}>
            <iframe
              style={{ borderWidth: 0}}
              ref={iframeNode}
              src={projectIndex >= 0 ? items[projectIndex].url : ""}
              width={projectIndex >= 0 ? playerWidth : 0}
              height={projectIndex >= 0 ? playerHeight : 0}
              allowFullScreen
             >
              <p>
                <a href="">
                  Fallback link for browsers that don't support iframes
                </a>
              </p>
            </iframe>
          </View>
          <View style={{ width: 300, marginLeft:20 }}>
            {deviceClass != "MOBILE" &&
            <ProjectItem
              isWebMobile={false}
              key={selectedIndex.current}
              titleColor={"#B6FF9E"}
              textColor={"#ffffff"}
              title={applicationName}
              description={description}
              tags={tags}
            />
          }
          </View>
        </View>
        <Pressable onPress={() => pressClose()} style={{ position: "absolute", top: 5, right: 5, zIndex: 999 }}>
          <Ionicons name="ios-close-circle" size={iconSize} color="#FFF" />
        </Pressable>
      </Animated.View>

      <Animated.View style={[player.container, { height: 0, width: 0, overflow: 'hidden' }]}>
        {items.map((item, index) => {
          const fadeInStyle = createFadeInAnimation(index);
          return (
            <TouchableOpacity style={{ zIndex: -index * 100, position: 'relative', }} key={index} onPress={() => pressOpen({ index })}>
              <Animated.View
                style={[
                  {
                    width: item.type === 'iphone' ? (width > 1366 ? 88 : 60) : (width > 1366 ? 180 : 150),
                    height: item.type === 'iphone' ? (width > 1366 ? 180 : 120) : (width > 1366 ? 130 : 100),
                    zIndex: -index,
                    position: 'relative',

                    borderRadius: 10,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 0,
                    },
                    shadowOpacity: 0.35,
                    shadowRadius: 20,
                    elevation: 5, // required for android


                  },
                  fadeInStyle,
                ]}
              >
                <View style={{
                  position: "absolute",
                  top: -10,
                  right: -10,
                  width: 38,
                  height: 38,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f9f9f9",
                  borderRadius: 500,
                  zIndex: 100
                }}>
                  <Foundation name="arrows-out" size={18} color="#4AC1AB" />
                </View>
                <Text></Text>
                <Image
                  source={images[item.image]}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 15,
                    position: 'relative',
                    zIndex: 0,
                    borderWidth: 4,

                  }}
                />
              </Animated.View>
            </TouchableOpacity>
          );
        })}
      </Animated.View>
    </SafeAreaView>
  );
};

export default ProjectsPlayer;
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, ScrollView, useWindowDimensions, Image, ImageBackground } from 'react-native';
import Animated, { useSharedValue, useAnimatedScrollHandler, useAnimatedStyle, withTiming, Easing } from 'react-native-reanimated';
import AnimatedPage from './components/AnimatedPage';
import LoadingView from './components/Loading';
import { useStyles } from './Global.style';
import fontsList from "./FontsList";
import { useFonts } from 'expo-font';
import NavigationBar from './components/NavigationBar';
import ProjectsPlayer from './components/ProjectsPlayerNew';
import AnimatedGeomShapes from './components/AnimatedGeoShapes';
import { LinearGradient } from 'expo-linear-gradient';
import {
  getDeviceClass,
  DESKTOP_LARGE,
  DESKTOP_MEDIUM,
  DESKTOP_SMALL,
  TABLET,
  MOBILE,
} from "./utils/DisplayHelper";

//pages
import Home from './pages/Home';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Playground from './pages/Playground';
//assets
const logoPositive = require('./assets/airro_logo_positive.svg')
const logoNegative = require('./assets/airro_logo_negative.svg')
const logoAlternative = require('./assets/airro_logo_alternative.svg')


const items = [
  {
    page: Home,
    bgColor: '#FFFFFF',
    textColor: "#222222",
    navBarColor: '#FFFFFF',
    shapeColor: '#EEEEEE',
    logo:logoPositive
  },
  {
    page : Projects,
    bgColor: '#222222',
    textColor: "#EEEEEE",
    navBarColor: '#FFFFFF',
    shapeColor: '#333333',
    logo:logoNegative
  },
  {
    page : Services,
    bgColor: '#50C9B3',
    textColor: "#000000",
    navBarColor: '#000000',
    shapeColor: '#50BBAA',
    logo:logoAlternative
  },
  {
    page : Playground,
    bgColor: '#469663',
    textColor: "#EEEEEE",
    navBarColor: '#FFFFFF',
    shapeColor: '#468855',
    logo:logoNegative
  }
];

let scrollAllowed = true // WE WANNA SYNC UPDATES
let isWebMobile = false // must be global to sync with the wheel listener
let wheelIndex = 0
let lastWheelPosTime = Date.now()
const App = () => {

  const { height, width } = useWindowDimensions();
  const { globalStyle, brand } = useStyles(width, height);
  const [showPlayer, setShowPlayer] = useState(false)
  const [projectIndex, setProjectIndex] = useState(-1);

  const deviceClass = getDeviceClass({width});
  
  isWebMobile = (width <= 820) ? true : false
  const pageHeights = useRef([0,0,0,0]);
  const paddingTop = isWebMobile ? 50 : 140
  
  const scrollY = useSharedValue(0);
  const [scrollYVal, setScrollYVal] = useState(0)
  const scrollViewFakeRef = useRef(null);
  const scrollViewRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navBarRef = useRef(null)
  let fontsBreakpoints = []
  // pageHeights.current = [0,0,0,0];

  //approximate the height for mobile childs mainly iphone 4
  const CHILD_HEIGHT =  (width <= 480 )? height * 1.5 : height
  const FAKE_CONTAINER_HEIGHT = (CHILD_HEIGHT * items.length)

   // necessary to get wheel events and pass to the fake scrollbar
  //  const onWheelHandle = (event) => {
  //   let diff = Date.now() - lastWheelPosTime // avoid get multiples events captures
  //   if (!isWebMobile && scrollViewFakeRef.current && diff > 50) {
  //     let newIndex = (event.deltaY > 0) ? wheelIndex+1: wheelIndex-1
  //     if (newIndex < 0) {
  //       newIndex = 0
  //     } else if (newIndex >= items.length) {
  //       newIndex = items.length - 1
  //     }
  //     if (newIndex != wheelIndex) {
  //       goToPage(newIndex)
  //     }
  //   }
  //   lastWheelPosTime = Date.now()
  // } 

  
  useEffect(() => {
   
  pageHeights.current = [0,0,0,0];

  }, [])


  //force the correct scrolling position after a resize
  useEffect(() => {
    if (scrollViewFakeRef && scrollViewFakeRef.current) {
      scrollY.value = CHILD_HEIGHT * activeIndex
      // scrollTo(scrollY.value, activeIndex)
    }    
  }, [height, width])


  function responsive(width) {
    if (deviceClass == DESKTOP_LARGE) {
      fontsBreakpoints.h1 = 60
      fontsBreakpoints.p = 18
    }else if (deviceClass == DESKTOP_MEDIUM) {
      fontsBreakpoints.h1 = 42
      fontsBreakpoints.p = 16
    } else if (deviceClass == DESKTOP_SMALL) {
      fontsBreakpoints.h1 = 42
      fontsBreakpoints.p = 16
    } else if (deviceClass == TABLET) {
      fontsBreakpoints.h1 = 36
      fontsBreakpoints.p = 14
    } else if (deviceClass == MOBILE) {
      fontsBreakpoints.h1 = 28
      fontsBreakpoints.p = 14
    }
  }

  responsive(width)

  const backgroundColor = useAnimatedStyle(() => {
    return {
      backgroundColor: withTiming(items[activeIndex].bgColor, { duration: 500 }),
    };
  }); 

  const onAnimationFinished = (index, _scrollAllowed) => {
    setActiveIndex(index);
    wheelIndex = index
    scrollAllowed = _scrollAllowed
    if (!scrollAllowed && scrollViewFakeRef.current) {
      let pagePosition = 0;
      if (index > 0) {
        pageHeights.current.slice(0, index).map((height) => {
          pagePosition += height;
        });
      }
      console.log("setting this value on animation finished", pagePosition);

      scrollY.value = pagePosition;
      scrollTo(scrollY.value, index)
    }
    setTimeout(() => { scrollAllowed = true }, 1000)
  };

  const onAnimationStarted = (index) => {
  
  };

  const onScroll = (scrollPosition) => {
    const y = parseInt(scrollPosition.nativeEvent.contentOffset.y)
    console.log("setting this value on scroll", y); 
    scrollY.value = y
    setScrollYVal(y)// force shapes redraw
    if (scrollAllowed) {
      // let newIndex = (isWebMobile) ?  Math.round(y / CHILD_HEIGHT) : Math.ceil(y / CHILD_HEIGHT);

      let newIndex = 0; 
      let lastHeight = 0; 
      for (let i = 0; i < pageHeights.current.length; i++) {
        if (y < (pageHeights.current[i] / 2 + lastHeight)) {
          newIndex = i;
          i = pageHeights.current.length;
        } else {
          lastHeight = lastHeight + pageHeights.current[i];
        }
      }

      if (newIndex >= items.length){
        newIndex = items.length - 1
      } 
      wheelIndex = newIndex
      if (navBarRef.current && navBarRef.current.handleScrollNavigation) {
        navBarRef.current.handleScrollNavigation(newIndex)
      }
    }
  };

  const scrollTo = (posY, index) => {
    if (scrollViewFakeRef.current) {
      if (isWebMobile) {
        // //workaround because on mobile the pages dont have the same height and are inside a real scrollview
        // //console.log("Scroll into view =>", items[index].page.name)
        // setActiveIndex(index)
        // wheelIndex = index
        // console.log("y value is", scrollY.value);
        // //extra space for header
        // if (index > 0) {
        //   document.getElementById(items[index].page.name).scrollIntoView({ behavior: 'auto', block: 'start' })
        //   setTimeout(() => {
        //     console.log("y value is", scrollY.value);
        //     scrollViewFakeRef.current.scrollTo({ y: scrollY.value, animated: false });
        //   }, 500)
        // } else {
        //   scrollViewFakeRef.current.scrollTo({ y: 0, animated: true });
        // }
        scrollViewFakeRef.current.scrollTo({ y: posY });
      } else {
        scrollViewFakeRef.current.scrollTo({ y: posY });
      }
    }
  }

  const goToPage = (index) => {
    setActiveIndex(index);
    wheelIndex = index
    let pagePosition = 0;
      if (index > 0) {
        pageHeights.current.slice(0, index).map((height) => {
          pagePosition += height;
        });
      }
    console.log("page heights are", pageHeights.current, pagePosition, index);

    scrollTo( pagePosition , index)
       
    setTimeout(() => { scrollAllowed = true }, 500)
  };
 
  const children = items.map((item, index) =>  (
    <View key={index} style={[styles.child, {
      minHeight: CHILD_HEIGHT,
      left: (isWebMobile) ? width * 0.10 : (width > 1366 ? width * 0.10 : width * 0.098),
      maxWidth: (isWebMobile) ? width * 0.7 : width * 0.86 ,
      paddingTop: paddingTop
    }]}
    onLayout={(event) => {
            let {height} = event.nativeEvent.layout;
            pageHeights.current[index] = height;    
            console.log("height is", height);
    }}
    >  
        <item.page 
            nativeID={item.page.name}
            style={{height: CHILD_HEIGHT}}
            textColor={item.textColor} 
            isWebMobile={isWebMobile}
            goToPageIndex={(index) => {
              goToPage(index)
            
            }}

            onPress={({index}) => {
              setProjectIndex(index);
              setShowPlayer(true);
//              console.log("the pressed index is", index)
              
            }}
        />
    </View>
  ));


  const [fontsLoaded] = useFonts(fontsList);

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      <LoadingView  />
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  const Brand = ({isWebMobile}) => {
    
    return (
      <Animated.View style={[brand.container, {zIndex:999, position: (isWebMobile) ? "relative" : "absolute", top: (isWebMobile) ? 5 : 20}]}>
        <img src={items[activeIndex].logo} style={{width: width > 900 ? width * 0.1 : 150, height:54}} />
      </Animated.View>
    )
  }
  

  const desktopRoot = () => {
    return (
      <Animated.ScrollView style={[styles.container, backgroundColor, { height: height }]} onLayout={onLayoutRootView}>

        {activeIndex > 0 && (
          <AnimatedGeomShapes
            items={items}
            activeIndex={activeIndex}
            scrollY={scrollYVal}
            scrollYMax={height * (children.length - 1)}
            style={{zIndex:-1}}

          />
        )}

        {/* <AnimatedPage activeIndex={activeIndex} style={{ height: height}} ref={scrollViewRef} scrollY={scrollY}   >
          {children}
        </AnimatedPage>
  
        <ScrollView
          style={{ width: 20, right: 0, height: height, zIndex: 0, position: 'absolute' }}
          ref={scrollViewFakeRef}
          scrollEventThrottle={16}
          onScroll={onScroll}
        >
          <View style={{ width: width, height: FAKE_CONTAINER_HEIGHT, backgroundColor: backgroundColor }} />
        </ScrollView> */}
        <ScrollView
          ref={scrollViewFakeRef}
          style={{ width: '100%', right: 0, height: height,  flexGrow: 1 }}
          scrollEventThrottle={16}
          onScroll={onScroll}
        >
          {children}
        </ScrollView>

        <NavigationBar
          ref={navBarRef}
          items={items}
          activeIndex={activeIndex}
          onAnimationFinished={onAnimationFinished}
          onAnimationStarted={onAnimationStarted}
          style={{ zIndex: activeIndex > 0 ? 2 : -999 }}
        />
        <Brand />
        <ProjectsPlayer
          style={{
            zIndex: showPlayer ? 2 : -999,
            display: showPlayer ? "block" : "none",
          }}
          projectIndex={projectIndex}
          showPlayer={showPlayer}
          didClose={() => {
            setProjectIndex(-1);
            setShowPlayer(false);
          }}
        />
      </Animated.ScrollView>
    );
  }

  const webMobileRoot = () => {
    return (
      <Animated.View style={[styles.container, backgroundColor, { height: height }]} onLayout={onLayoutRootView}>
        <Brand isWebMobile={true}  />
        <ScrollView
          ref={scrollViewFakeRef}
          style={{ width: '100%', right: 0, height: height,  flexGrow: 1 }}
          scrollEventThrottle={16}
          onScroll={onScroll}
        >
          {children}
        </ScrollView>

        <ProjectsPlayer
          style={{
            zIndex: showPlayer ? 999 : -999,
            display: showPlayer ? "block" : "none",
          }}
          projectIndex={projectIndex}
          showPlayer={showPlayer}
          didClose={() => {
            setProjectIndex(-1);
            setShowPlayer(false);
          }}
        />
        
        <NavigationBar
        ref={navBarRef}
        items={items}
        activeIndex={activeIndex}
        onAnimationFinished={onAnimationFinished}
        onAnimationStarted={onAnimationStarted}
        isWebMobile={isWebMobile}
        style={{ zIndex: (activeIndex > 0) ? 2 : -999}}
      />

   
     
  
      </Animated.View>
    );
  }


  return (isWebMobile) ? webMobileRoot() : desktopRoot()

};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    position: "absolute",
    width: "100%",
    overflow: "hidden",
  },
  child: {
    width: '100%',
    borderWidth: 0,

  },

});

export default App;
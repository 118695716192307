import { View, Text, useWindowDimensions } from 'react-native';
import { useStyles } from '../Global.style';
import ProjectItem from '../components/ProjectItem';
import {
    getDeviceClass,
    DESKTOP_LARGE,
    DESKTOP_MEDIUM,
    DESKTOP_SMALL,
    TABLET,
    MOBILE,
  } from "../utils/DisplayHelper";

const images = {
    joyntly: require('../assets/joyntly_iphone_folder.png'),
    restaurant: {uri:"https://cdn.dribbble.com/users/1387827/screenshots/15052350/media/d998b76b3e61c9bff9c19c4d72d7c9b6.png?compress=1&resize=1000x750&vertical=top"},
    sportsbox: require('../assets/sportsbox.jpeg'),

};

const items = [
    {
        image:'sportsbox',
        title: "SportsBox",
        tags: ["iOS", "React Native"],
        title: "SportsBox",
        tags: ["ChatGPT", "Generative AI", "React Native", ""],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel blandit metus, vel ullamcorper elit. Morbi ac cursus augue. Duis turpis odio, cursus at dui eget, venenatis sollicitudin velit. Phasellus imperdiet sapien id suscipit efficitur.",
    },
    {
        title: "Restaurant",
        tags: ["Web", "Android"],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel blandit metus, vel ullamcorper elit. Morbi ac cursus augue. Duis turpis odio, cursus at dui eget, venenatis sollicitudin velit. Phasellus imperdiet sapien id suscipit efficitur.",
    },
    {
        image:'joyntly',
        title: "Joyntly",
        tags: ["React Native",],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel blandit metus, vel ullamcorper elit. Morbi ac cursus augue. Duis turpis odio, cursus at dui eget, venenatis sollicitudin velit. Phasellus imperdiet sapien id suscipit efficitur.",
    }
];

export default function Playground(props) {

    const { height, width, scale, fontScale } = useWindowDimensions();
    const { globalStyle } = useStyles(width, height);
    let fontsBreakpoints = []
    const deviceClass = getDeviceClass({ width });
    const openPlayer = props.openPlayer;

    function responsive() {
        if (deviceClass == DESKTOP_LARGE) {
          fontsBreakpoints.h2 = 36
          fontsBreakpoints.p = 18
        } else if (deviceClass == DESKTOP_MEDIUM) {
          fontsBreakpoints.h2 = 36
          fontsBreakpoints.p = 16
        } else if (deviceClass == DESKTOP_SMALL) {
          fontsBreakpoints.h2 = 32
          fontsBreakpoints.p = 16
        } else if (deviceClass == TABLET) {
          fontsBreakpoints.h2 = 30
          fontsBreakpoints.p = 14
        } else if (deviceClass == MOBILE) {
          fontsBreakpoints.h2 = 26
          fontsBreakpoints.p = 14
        }
      }
    
      responsive()

    const projects = items.map((item, index) => {

        return (
                <ProjectItem
                    isWebMobile={props.isWebMobile}
                    key={index}
                    titleColor={"#B6FF9E"}
                    image={images[item.image]}
                    textColor={props.textColor}
                    title={item.title}
                    description={item.description}
                    tags={item.tags}
                    onPress={() => {
                        if(props.onPress) {
                            props.onPress({index,item});
                        }
                    }}                    

                />
        )

    });

    return (

        <View nativeID={props.nativeID} >
            <Text style={[globalStyle.h2, {
                color: props.textColor,
                fontSize: fontsBreakpoints.h2,
                lineHeight: fontsBreakpoints.h2 + 12
            }]}
                accessibilityRole="header"
                aria-level="2">
                Playground
            </Text>
            <View style={{ flexDirection: (props.isWebMobile) ? "column" : "row" }}>
                {projects}
            </View>
        </View>

    )

}







import React, { useEffect, Children, useRef, forwardRef, useState, useImperativeHandle } from 'react';
import { View, StyleSheet, Dimensions, Touchable, TouchableOpacity, useWindowDimensions } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedScrollHandler,
  interpolate,
  Extrapolate,
  interpolateColor,
  withTiming,
  Easing,
  runOnJS
} from 'react-native-reanimated';
import { isWeb } from '../PlatformSpecific';

let animating = false
let scrollAllowed = true

const NavigationBar = forwardRef((props, ref) => {

  const {height, width, scale, fontScale} = useWindowDimensions();

  const itemHeight = (height /  props.items.length) - (width > 1366 ? 100 : 85);
  const itemBottomMargin = 5

  if (isWeb) {
    window._frameTimestamp = null//withTiming issue
  }
  
  const [activeIndex, setActiveIndex] = useState(props.activeIndex);
  const toIndex = useSharedValue(props.activeIndex);
  const fromIndex = useSharedValue(props.activeIndex);

  const handleNavigation = (index) => {
    if (index == activeIndex) {
      return
    }
    animating = true
    scrollAllowed = false
    //console.log(" activeIndex", activeIndex, " to ", index)
    toIndex.value = index
    if (props.onAnimationStarted) {
      props.onAnimationStarted(index)
    }
    
  }

  const handleScroll = (index) => {
    if (index == activeIndex) {
    
      return
    }
    if (!scrollAllowed) {
      console.log("SCROLL NOT ALLOWED")
      return
    }
    //console.log(" handleScroll activeIndex", activeIndex, " to ", index)
    toIndex.value = index
    animating = true
  }

  const instance = {
    handleScrollNavigation: handleScroll
  }
  
  useImperativeHandle(ref, () => instance);

  const Touchable = ({ onPress, children, style }) => {
    return (
        <TouchableOpacity onPress={onPress} style={style}>
          {children}
        </TouchableOpacity>
      );    
  };

  const createItemStyle = (index) => {
    return useAnimatedStyle(() => {
      if (!animating) {
        return {
          top: index * (itemHeight + itemBottomMargin)
        }
      }
      
      let idx = index
      if (idx == toIndex.value) {
        idx = fromIndex.value
      } else if (idx == fromIndex.value) {
        idx = toIndex.value
      }

      return {
        top: withTiming( idx * (itemHeight + itemBottomMargin), {
            duration: 300,
            easing: Easing.linear,
          }, (obj) => {
         
            if (props.onAnimationFinished && idx == toIndex.value && animating) {
              toIndex.value = idx
              fromIndex.value = idx
              animating = false
              setActiveIndex(idx)
              props.onAnimationFinished(idx, scrollAllowed)
              setTimeout(() => { scrollAllowed = true }, 400)
            }
          }
        ),
      };
    });
  };

  let navBarColor = props.items[activeIndex].navBarColor
  if (navBarColor.length < 7) {
    for (let i=navBarColor.length; i < 7; i++) {
      navBarColor += "0"
    }
  }
  const r = parseInt(navBarColor.slice(1, 3), 16);
  const g = parseInt(navBarColor.slice(3, 5), 16);
  const b = parseInt(navBarColor.slice(5, 7), 16);
  const navBarColorAlpha = "rgba("+ r +", "+ g +", " + b + ", 0.2)"
  
  const navigationItems = props.items.map((_, index) => {

    return (<Animated.View style={createItemStyle(index)} key={index}>
        <Touchable
          key={index}
          onPress={() => handleNavigation(index)}
          style={{
            position:"absolute",
            width: width >= 1366 ? 6 : 5,
            backgroundColor: activeIndex === index ? navBarColor : navBarColorAlpha,
            height: itemHeight,
          }}
        />
      </Animated.View>)
  }
  
  );

  //console.log(height - (200 * props.items.length))

  return (
    <View  style={{ position: 'absolute',flexDirection: 'column',left: (props.isWebMobile) ? width * 0.02: (width >= 1366 ? 80 : 50), top:height * 0.15  }}>
      {navigationItems}
    </View>
  )
})


export default NavigationBar
import { StyleSheet } from 'react-native';

/* fonts available

    'Avenir-Book'
    'Avenir-Roman'
    'Avenir-Black'
    'AvenirNext-Regular'
    'AvenirNext-Bold'
    'AvenirNext-it'
*/

//colors const
const PRIMARY_COLOR = "#328fdb"
const SECONDARY_COLOR = "#32dba9"
const TEXT_COLOR = "#000"
const TEXT_NEGATIVE_COLOR = "#FFF"
const BG_1 = "#2685bd"
const BG_2 = "#328fdb"
const BG_3 = "#fff"

const H1_COLOR = "#fff"
const H2_COLOR = "#fff"
const H3_COLOR = "#fff"

//text consts
const TEXT_SIZE = 24
const TEXT_LINE_HEIGHT = 22
const H1_SIZE = 42
const H2_SIZE = 36
const H3_SIZE = 30

export const useStyles = (width, height) => {
  const globalStyle = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: BG_1,
      alignItems: 'flex-start',
      justifyContent: "flex-start",
      width: "100%"
    },
    h1: {
      fontSize: H1_SIZE,
      color: H1_COLOR,
      fontFamily: 'Avenir-Book',
      lineHeight: H1_SIZE + 10,
      marginBottom: 20
    },
    h2: {
      fontSize: H2_SIZE,
      color: H2_COLOR,
      fontFamily: 'Avenir-Book',
      lineHeight: H2_SIZE + 10,
    },
    h3: {
      fontSize: H3_SIZE,
      color: H3_COLOR,
      fontFamily: 'AvenirNext-Bold',
      lineHeight: H3_SIZE + 10,
    },
    p: {
      fontSize: TEXT_SIZE,
      color: TEXT_NEGATIVE_COLOR,
      fontFamily: 'Avenir-Book',
    },
    pN: {
      fontSize: TEXT_SIZE,
      color: TEXT_NEGATIVE_COLOR,
      fontFamily: 'AvenirNext-Regular',
      lineHeight: TEXT_LINE_HEIGHT
    },
    circle: {
      width: 100,
      height: 100,
      borderRadius: 500,
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 2,
      borderColor: PRIMARY_COLOR
    },
    separatorLine:{
      flex: 1,
      position: "relative",
      width: width * 0.7,
      height: 1,
      marginVertical:10,
      borderBottomColor: 'rgba(0,0,0,0.1)',
      borderBottomWidth: 2,
    }
  });

  const loading = StyleSheet.create({
    container: {
      height: "110%",
      width: "100%",
      backgroundColor: BG_3,
      alignItems: 'center',
      justifyContent: 'center'
    },
    horizontal: {
      flexDirection: "row",
    }
  });

  function brandPosition(){
    if (width > 1920) {
      return width * 0.089
    } else if (width >= 1600) {
      return width * 0.099
    } else if (width < 1600 && width >= 1366) {
      return width * 0.098
    } else if (width < 1366 && width >= 1200) {
      return width * 0.098
    } else if (width < 1200 && width >= 600) {
      return width * 0.098
    } else if (width <= 600) {
      return width * 0.098
    }
  }

  const brand = StyleSheet.create({
    container: {
      position: "absolute",
      top: height * 0.03,
      left: brandPosition(),
      textAlign:"left"
    },
    text: {
      fontSize: 48,
      color: "#000",
      fontFamily: 'Avenir-Black',
      letterSpacing: 1,
      lineHeight: 48,
      marginBottom: 20
    }
  });

  

  const player = StyleSheet.create({
    container: {
      flex:1,
      width: width * 0.5,
      maxWidth:width >= 1366 ? 420 : 350,
      height: 215,
      position: "absolute",
      top: height >= 830 ?  height * 0.75 : (height < 700 ? height * 0.65 : height * 0.69 ) ,
      left: width > 1366 ? 70 : 55,
      flexDirection:"row",
      alignItems:"flex-end",
      justifyContent:"space-around",
    },
    text: {
      fontSize: 48,
      color: "#000",
      fontFamily: 'Kanit-Bold',
      letterSpacing: 1,
      lineHeight: 48,
      marginBottom: 20
    }
  });


  const greenButton = StyleSheet.create({
    container: {
      backgroundColor: "#50C9B3",
      borderRadius: 10,
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 20,
      paddingLeft: 20,
      flex: 1,
      alignItems: "center",
      top: -8,
      cursor: 'pointer'
    },
    text: {
      fontSize: 16,
      color: "#000",
      fontFamily: 'Avenir-Book'
    }
  });

  return { globalStyle, loading, brand, player, greenButton };
};




export const DESKTOP_LARGE = "DESKTOP_LARGE"
export const DESKTOP_MEDIUM = "DESKTOP_MEDIUM"
export const DESKTOP_SMALL = "DESKTOP_SMALL"
export const TABLET = "TABLET"
export const MOBILE = "MOBILE"

export function getDeviceClass({ width }) {
    
  if (width >= 1600) {
    return DESKTOP_LARGE;
  } else if (width < 1600 && width >= 1366) {
    return DESKTOP_MEDIUM;
  } else if (width < 1366 && width >= 1200) {
    return DESKTOP_SMALL;
  } else if (width < 1200 && width >= 600) {
    return TABLET;
  } else if (width < 600) {
    return MOBILE;
  }
}


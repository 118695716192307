import { View, Text, useWindowDimensions } from 'react-native';
import { useStyles } from '../Global.style';
import ProjectItem from '../components/ProjectItem';
import {
  getDeviceClass,
  DESKTOP_LARGE,
  DESKTOP_MEDIUM,
  DESKTOP_SMALL,
  TABLET,
  MOBILE,
} from "../utils/DisplayHelper";

const images = {
  taco_bell: {uri: "https://content.pymnts.com/wp-content/uploads/2020/10/Taco-Bell-digital-gift-768x512.jpg" },
  finelite: {uri: "https://www.mramitpatel.com/static/456fc9f451ea47440504cfa599b8b611/57279/finelite-all-screens.webp"},
  airguard: require('../assets/airguard.png'),
};


const items = [
  {
    image: 'taco_bell',
    title: "Taco Bell",
    tags: ["Native Android Development", "Kotlin", "POS", "Couchbase", "Thermal Printers", "Drive Thru", "UI/UX", "Cash"],
    description: "By partnering with Yum! Brands, Airro developed a customized, multichannel ecommerce platform, including point-of-sale systems for hundreds of Taco Bell stores, resulting in increased revenue and operational efficiencies. "
  },
  {
    image: 'finelite',
    title: "FineLite",
    tags: ["Native iOS Development", "Swift", "Native Android", "Kotlin", "Bluetooth", "Lighting System", "Security", "UI/UX"],
    description: "Airro partnered with Finelite to create the FineTUNE app for controlling the world's first programmable lighting system for commercial spaces, allowing users to customize lighting levels, color, and temperature for natural, comfortable, and healing environments.",
  },
  {
    image: 'airguard',
    title: "Air Guard",
    tags: ["Web Development", "React", "2D Animation", "Drones", "GEO JSON", "Websockets", "UX/UX", "AWS"],
    description: "Meet AirGuard, the revolutionary drone detection software platform designed and developed by Airro Studio's expert team. With its advanced RF sensors, AirGuard collects and manages real-time data on drones flying in your airspace, providing valuable insights on their GPS location, make and model, altitude, and speed, allowing your team to set up the right safety and security measures to protect your airspace from aerial drone threats.",
  },
];

//console.log(items[0].tags)

export default function Projects(props) {

  const { height, width, scale, fontScale } = useWindowDimensions();
  const { globalStyle } = useStyles(width, height);
  const deviceClass = getDeviceClass({ width });
  let fontsBreakpoints = []

  function responsive() {
    if (deviceClass == DESKTOP_LARGE) {
      fontsBreakpoints.h2 = 36
      fontsBreakpoints.p = 18
    } else if (deviceClass == DESKTOP_MEDIUM) {
      fontsBreakpoints.h2 = 36
      fontsBreakpoints.p = 16
    } else if (deviceClass == DESKTOP_SMALL) {
      fontsBreakpoints.h2 = 32
      fontsBreakpoints.p = 16
    } else if (deviceClass == TABLET) {
      fontsBreakpoints.h2 = 30
      fontsBreakpoints.p = 14
    } else if (deviceClass == MOBILE) {
      fontsBreakpoints.h2 = 26
      fontsBreakpoints.p = 14
    }
  }

  responsive()

  const projects = items.map((item, index) => {
    return (
      <ProjectItem
        isWebMobile={props.isWebMobile}
        key={index}
        titleColor={"#50C9B3"}
        textColor={props.textColor}
        title={item.title}
        image={images[item.image]}
        description={item.description}
        tags={item.tags}

      />
    )

  });

  return (

    <View nativeID={props.nativeID}>
      <Text style={[globalStyle.h2, {
        color: props.textColor,
        fontSize: fontsBreakpoints.h2,
        lineHeight: fontsBreakpoints.h2 + 12
      }]}
        accessibilityRole="header"
        aria-level="2">
        Projects
      </Text>
      <View style={{ flexDirection: (props.isWebMobile) ? "column" : "row" }}>
        {projects}
      </View>
    </View>

  )

}







let fontsList =  {
    'Avenir-Book': require('./assets/fonts/Avenir-Book.otf'),
    'Avenir-Roman': require('./assets/fonts/Avenir-Roman.otf'),
    'Avenir-Black': require('./assets/fonts/Avenir-Black.otf'),
    'AvenirNext-Regular': require('./assets/fonts/AvenirNext-Regular.otf'),
    'AvenirNext-Bold': require('./assets/fonts/AvenirNext-Bold.otf'),
    'AvenirNext-it': require('./assets/fonts/AvenirNext-it.otf'),

 }

 export default fontsList